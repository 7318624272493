<template>
  <div class="box">
     <router-link
      v-if="$can('admin/number/create')"
      :to="{ path: '/number/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建桌号
      </el-button>
    </router-link>

    <div class="box-toolbar">
      <div class="box-item">
        <el-select
          v-model="shopId"
          placeholder="请选择"
          @change="changemodel"
        >
          <el-option
            v-for="(item,index) in shops"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入桌号"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
       @selection-change="handleSelectionChange">
       <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="120"
        label="桌号"
        prop="type_name_number"
      >
      </el-table-column>

      <el-table-column
        label="店铺"
        prop="store_id"
      >
       <template slot-scope="scope">
          <p>{{scope.row.store.bsName}}</p>
        </template>
      </el-table-column>

       <el-table-column
        label="店铺地址"
        prop="store_id"
      >
       <template slot-scope="scope">
          <p>{{scope.row.store.bsAddress}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="二维码图片"
        prop="code_cover"
      >
        <template slot-scope="scope">
          <img
            class="shop-img"
            @click="dialog(http_url + scope.row.code_cover)"
            :src="http_url + scope.row.code_cover"
            alt=""
          >
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button type="danger"
          plain
          size="mini"

              v-if="$can('admin/number/delete')"
                @click.native="handleModelDelete(scope.row)" >删除
                </el-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="shop-i-bottom">
      <div>
      <el-button
          type="primary"
          plain
          v-if="$can('admin/number/export')"
          @click.native="Export"
        >
          下载所选
        </el-button>
         <el-button
          type="danger"
          plain
          v-if="$can('admin/number/delete-all')"
          @click.native="delAll"
        >
          删除所选
        </el-button>
      </div>
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
    <!--  -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="400"
    >
      <div class="dialog-img">
        <img
          :src="dialogImgPath"
          alt=""
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import NumberService from '@admin/services/NumberService'
import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import Auth from '@admin/utils/auth'

export default {
  name: 'NumberIndex',
  data () {
    return {
      http_url: '',
      ListArr: [],
      dialogVisible: false,
      dialogImgPath: '',
      shops: [],
      shopId: 0, //  所选店铺
      search_name: '',
      function: function (param) {
        return NumberService.all(param)
      },
      delete: function (id) {
        return NumberService.delete(id)
      }
    }
  },
  async created () {
    this.http_url = Auth.getHostUrl()
    await this.getShops()
  },
  components: {
    Page
  },
  mixins: [pagination],

  methods: {
    // 显示图片mack
    dialog (imgPath) {
      this.dialogVisible = true
      this.dialogImgPath = imgPath
    },
    // 获取店铺
    async getShops () {
      const { data } = await flatry(NumberService.shops())
      let shop = [
        {
          id: 0,
          name: '全部店铺'
        }
      ]
      if (data) {
        this.shops = shop.concat(data.data)
      } else {
        this.shops = shop
      }
    },
    // 切换店铺
    changemodel (e) {
      this.searchGo()
    },
    // 搜索吧~
    async searchGo () {
      this.page = 1
      let param = {
        type: this.type,
        type_name_number: this.search_name,
        store_id: this.shopId
      }
      this.where = param
      await this.getList(param)
    },
    async Export () {
      if (this.ListArr.length <= 0) {
        this.$message.warning('请选择需要下载的数据')
        return
      }
      const { data } = await flatry(NumberService.export(this.ListArr))
      if (data) {
        this.$message.success(data.msg)
        window.open(data.data, '_blank')
        // this.$router.replace({ path: '/number/' })
      }
    },
    async delAll () {
      if (this.ListArr.length <= 0) {
        this.$message.warning('请选择需要删除的数据')
        return
      }
      console.log(this.ListArr)
      const { data } = await flatry(NumberService.deleteAll(this.ListArr))
      if (data) {
        for (let i = 0; i < this.ListArr.length; i++) {
          for (let j = 0; j < this.lists.length; j++) {
            if (this.ListArr[i].id === this.lists[j].id) {
              this.lists.splice(j, 1)
              break
            }
          }
        }
        this.$message.success(data.msg)
      }
    },
    handleSelectionChange (val) {
      this.ListArr = val
    }
  }
}

</script>
<style lang='scss' scoped>
.shop-img{
  width: 50px;
  height: 50px;
}
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}
.dialog-img{
  img{
    width: 300px;
    height: 300px;
  }
}
</style>
