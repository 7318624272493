import Vue from 'vue'

const NumberService = {
  // 桌号列表
  all (param) {
    return Vue.http.get('number/index', param)
  },

  // 获取所有店铺
  shops () {
    return Vue.http.get('auth/branch-shop')
  },
  // 创建桌号
  create (model) {
    return Vue.http.post('number/create', model)
  },
  // 删除桌号
  delete (param) {
    return Vue.http.delete('number/delete', param)
  },
  deleteAll (param) {
    return Vue.http.delete('number/delete-all', param)
  },
  export (param) {
    return Vue.http.download('POST', 'number/export', null, param)
  },
  // 反馈列表
  feedback (param) {
    return Vue.http.get('feedback/index', param)
  },
  feedDel (param) {
    return Vue.http.delete('feedback/delete', param)
  }
}

export default NumberService
